<template>
  <div id="productDetail">
    <!--头部导航-->
    <div style="z-index:9999;position:fixed;top:0;left: 0;">
      <baseNav></baseNav>
    </div>

    <div class="producrDetailCon">
      <div class="slidePage">
        <div class="title" v-if="detailData.title" v-html="detailData.title"></div>
        <div class="slideCon">
          <div class="swiper-container mySwiper" >
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="item in slideList" :key="item.id">
                <div class="items">
                  <img :src="item.pic_image" alt="">
                </div>
              </div>

            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>

      </div>

      <div class="detailConBox">
        <div class="pro_video" v-show="detailData.video&&detailData.video.length>0">
          <img :src="detailData.video_poster"/>
          <div class="pro_video_icon" @click="toVideo(detailData.video)"></div>
        </div>
        <!--        产品概述-->
        <div class="tit tit_one" v-if="detailData.intro&&detailData.intro.length>0" v-html="detailData.function_title"></div>
        <!-- <div class="tit" v-show="thisId=12">创新功能</div> -->

        <!-- <div class="tit"  v-show="thisId==12" v-if="detailData.intro&&detailData.intro.length>0" v-html="detailData.function_title"></div> -->

        <div class="chanpinCon" v-if="detailData.intro&&detailData.intro.length>0" v-html="detailData.intro" style="margin-bottom: 3.334vw;font-size: 3.2vw;"></div>

        <!--        创新功能-->
               <!-- <div class="tit" v-show="thisId=12">创新功能</div> -->
               <!-- <div class="tit" v-show="thisId==2">三大多模态技术</div> -->
               <div class="tit"  v-show="thisId==12" v-if="detailData" v-html="detailData.function_title"
               style="margin:3vw 0 4.222vw;"
               ></div>
        <div class="chuangxinCon" v-show="paramsList&&paramsList.length>0">
          <el-collapse v-model="activeNames" accordion @change="handleChange" >
            <el-collapse-item :title="item.title" :name="index" v-for="(item,index) in paramsList" :key="item.id">
              <div class="detailImg">
                <img :src="item.pic_image" :alt="item.title">
                <div class="videoIcon" v-show="item.video_file&&item.video_file.length>0" @click="toVideo(item.video_file)"></div>

              </div>
              <div class="desc_title" v-html="item.desc"></div>
            </el-collapse-item>
          </el-collapse>
        </div>


        <div v-show="thisId==10">
          <div class="tit">Innovative Features for Clinical Needs</div>
          <ul class="scanCon">
            <li>
              <div class="pic fl">
                <img src="../assets/images/p80_01.jpg" alt="">
              </div>
              <div class="scan_con fr">
                <div class="scan_tit" style="color:#20BFD0;font-weight: bold;">Full view Mode</div>
                <div class="scan_desc">Complete an ultra-long pullback of 80mm within 2 seconds</div>
              </div>
            </li>

            <li>
              <div class="pic fl">
                <img src="../assets/images/p80_02.jpg" alt="">
              </div>
              <div class="scan_con fr">
                <div class="scan_tit" style="color:#20BFD0;font-weight: bold;">High definition Mode</div>
                <div class="scan_desc">
                  Complete an ultra HD scan of 55mm within 2.75 seconds
                </div>
              </div>
            </li>

            <li>
              <div class="pic fl">
                <img src="../assets/images/p80_03.jpg" alt="">
              </div>
              <div class="scan_con fr">
                <div class="scan_tit" style="color:#20BFD0;font-weight: bold;">Super Fast Mode</div>
                <div class="scan_desc">
                  Compelte a super fast pullback length of 40mm in 1 second
                </div>
              </div>
            </li>
          </ul>

          <!-- <div class="tit">功能创新与图像优化</div> -->
          <div class="picYouhuaCon">
            <img src="../assets/uploads/pic_zidongjisuan.jpg" style="margin-bottom:2.222vw" alt="">
            <div style="font-weight: 600;font-size:3.8888vw;line-height: 5.5555vw;margin-bottom:1.1vw">Auto-Calculation of Neointimal Coverage</div>
            <div style="font-weight: 400;line-height: 4.5833vw;font-size:3.3333vw;text-align: left;">Automatic assessment of neointimal  coverage after PCI to provide guidance for DAPT(dual antiplatelet therapy)adjustment </div>

            <img src="../assets/uploads/pic_brs.png" style="margin:6.944vw 0 2.222vw" alt="">
            <div style="font-weight: 600;font-size:3.8888vw;line-height: 5.5555vw;margin-bottom:1.1vw">Intelligent Evaluation of BRS</div>
            <div style="font-weight: 400;line-height: 4.5833vw;font-size:3.3333vw;text-align: left;">Provide automatic evaluation for  bioresorbable stents (BRS) after implantation </div>

            <img src="../assets/uploads/pic_acr.jpg" style="margin:6.944vw 0 2.222vw" alt="">
            <div style="font-weight: 600;font-size:3.8888vw;line-height: 5.5555vw;margin-bottom:1.1vw">ACR (angiographic co-registration)</div>
            <div style="font-weight: 400;line-height: 4.5833vw;font-size:3.3333vw;text-align: left;">Shorten OCT learning curve, reducing procedure time & volume of contrast</div>

            <img src="../assets/uploads/pic_aqa.png" style="margin:6.944vw 0 2.222vw" alt="">
            <div style="font-weight: 600;font-size:3.8888vw;line-height: 5.5555vw;margin-bottom:1.1vw">Auto Quantitative Assessment  for Bifurcation Lesions</div>
            <div style="font-weight: 400;line-height: 4.5833vw;font-size:3.3333vw;text-align: left;">Predict risk of side branch occlusion, adopting optimal strategy for bifurcation lesions</div>
          </div>
        </div>
        <!-- P80EOCT模块 id：10 -->
        <div class="tit"  v-show="thisId==10" v-if="detailData.solutions" v-html="detailData.solutions.title"></div>
        

        <div class="chuangxinCon" v-show="thisId==10" >
          <el-collapse v-model="activeNames" accordion @change="handleChange" >
            <el-collapse-item :title="item.title" :name="index" v-for="(item,index) in octList.content" :key="item.id">
              <div class="detailImg">
                <img :src="item.pic_image" :alt="item.title">
                <div class="videoIcon" v-show="item.video_file&&item.video_file.length>0" @click="toVideo(item.video_file)"></div>

              </div>
              <div class="desc_title" v-html="item.content"></div>
            </el-collapse-item>
          </el-collapse>
        </div>


        <!--        注册信息-->
        <div class="tit">Specifications</div>
        <span style="display: block;width: 100%;height: 0.55vw;background: #d8d8d8;"></span>
        <div class="parameCon" v-html="detailData.parame" ></div>


        <!--        注册信息-->
        <div class="tit">Registration</div>
        <div class="canshuCon1" v-html="detailData.register_info"></div>

      </div>
    </div>

    <div style="margin-top:16.666vw;">
      <Copyright></Copyright>
    </div>
    <!--    弹出层-->
    <van-overlay :show="isMaskShow" @click="closeVideo">
      <div class="videowrapper">
        <video-player class="video-player vjs-custom-skin"
                      ref="videoPlayer"
                      :playsinline="true"
                      :poster="detailData.video_poster"
                      :options="playerOptions">
        </video-player>
      </div>
    </van-overlay>
  </div>
</template>

<script>
  import baseNav from "./baseNav";
  import Copyright from "@/component/Copyright";
  import Swiper from 'swiper';
  import {GetProductDetailData} from "@/api/api";
  export default {
    data(){
      return{
        //当前产品id
        thisId:0,
        //轮播信息
        slideList:[],
        // oct信息列表
        octList:{},
        //产品所有信息
        detailData:{},
        //参数信息列表
        paramsList:[],
        activeNames:[0],
        // first-in-class OCT Solutions
        //     octList:[
        //     {
        //         create_time:"",
        //         desc:"Automatic assessment of neointimal coverage after PCI to provide guidance for DAPT(antiplatelet therapy)adjustment",
        //         id:1,
        //         lang:"en",
        //         pic_image:require("../assets/uploads/pic_zidongjisuan.png"),
        //         product_id:"1",
        //         thumb_image:"",
        //         title:"Auto-Calculation of Neointimal coverage",
        //         update_time:"",
        //         video_file:"",
        //         weight:""
        //     },
        //     {
        //         create_time:"",
        //         desc:"Automatic assessment of neointimal coverage after PCI to provide guidance for DAPT(antiplatelet therapy)adjustment",
        //         id:2,
        //         lang:"en",
        //         pic_image:require("../assets/uploads/pic_zidongjisuan.png"),
        //         product_id:"2",
        //         thumb_image:"",
        //         title:"Intelligent Evaluation of BRS",
        //         update_time:"",
        //         video_file:"",
        //         weight:""
        //     },
        //     {
        //         create_time:"",
        //         desc:"Automatic assessment of neointimal coverage after PCI to provide guidance for DAPT(antiplatelet therapy)adjustment",
        //         id:3,
        //         lang:"en",
        //         pic_image:require("../assets/uploads/pic_zidongjisuan.png"),
        //         product_id:"3",
        //         thumb_image:"",
        //         title:"ACR(angiographic co-registration)",
        //         update_time:"",
        //         video_file:"",
        //         weight:""
        //     },
        //     {
        //         create_time:"",
        //         desc:"Automatic assessment of neointimal coverage after PCI to provide guidance for DAPT(antiplatelet therapy)adjustment",
        //         id:4,
        //         lang:"en",
        //         pic_image:require("../assets/uploads/pic_zidongjisuan.png"),
        //         product_id:"4",
        //         thumb_image:"",
        //         title:"Auto Quantitative Assessment for Bifurcation...",
        //         update_time:"",
        //         video_file:"",
        //         weight:""
        //     },
        //     {
        //         create_time:"",
        //         desc:"Automatic assessment of neointimal coverage after PCI to provide guidance for DAPT(antiplatelet therapy)adjustment",
        //         id:5,
        //         lang:"en",
        //         pic_image:require("../assets/uploads/pic_zidongjisuan.png"),
        //         product_id:"5",
        //         thumb_image:"",
        //         title:"Pre & Post PCI Image Comparison",
        //         update_time:"",
        //         video_file:"",
        //         weight:""
        //     },
        //     {
        //         create_time:"",
        //         desc:"Automatic assessment of neointimal coverage after PCI to provide guidance for DAPT(antiplatelet therapy)adjustment",
        //         id:6,
        //         lang:"en",
        //         pic_image:require("../assets/uploads/pic_zidongjisuan.png"),
        //         product_id:"6",
        //         thumb_image:"",
        //         title:"Continuous Calibration",
        //         update_time:"",
        //         video_file:"",
        //         weight:""
        //     },
        //     {
        //         create_time:"",
        //         desc:"Automatic assessment of neointimal coverage after PCI to provide guidance for DAPT(antiplatelet therapy)adjustment",
        //         id:7,
        //         lang:"en",
        //         pic_image:require("../assets/uploads/pic_zidongjisuan.png"),
        //         product_id:"7",
        //         thumb_image:"",
        //         title:"Auto Assessment of Stent Apposition",
        //         update_time:"",
        //         video_file:"",
        //         weight:""
        //     },
        //     {
        //         create_time:"",
        //         desc:"Automatic assessment of neointimal coverage after PCI to provide guidance for DAPT(antiplatelet therapy)adjustment",
        //         id:8,
        //         lang:"en",
        //         pic_image:require("../assets/uploads/pic_zidongjisuan.png"),
        //         product_id:"8",
        //         thumb_image:"",
        //         title:"Auto Assessment of Stent Expansion",
        //         update_time:"",
        //         video_file:"",
        //         weight:""
        //     },
        // ],
        //激活id
        isActiveId:0,
        isarrowUp:false,
        isMaskShow:false,
        playerOptions: {
          playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
          autoplay: true, // 如果为true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 是否视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            type: "video/mp4", // 类型
            src: ""  // url地址
          }],
          poster: '', // 封面地址
          notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true, // 当前时间和持续时间的分隔符
            durationDisplay: true, // 显示持续时间
            remainingTimeDisplay: false, // 是否显示剩余时间功能
            fullscreenToggle: true // 是否显示全屏按钮
          }
        }
      }
    },
    mounted() {
      let theId=this.$route.query.id
      this.thisId=theId
      this.toGetProductDetailData(theId)
    },
    methods:{
      toGetProductDetailData(id){
        let _this=this
        GetProductDetailData(id)
                .then(r=>{
                  let res=r.data
                  if(res.status!=1){
                    return
                  }
                  _this.slideList=res.data.slide
                  _this.isActiveId=res.data.functions[0].id
                  _this.detailData=res.data
                  console.log(_this.detailData);
                  _this.paramsList=_this.detailData.functions
                  _this.octList = _this.detailData.solutions
                  console.log(_this.octList);
                  _this.isActiveId=_this.detailData.functions[0].id
                  console.log('---------')
                  console.log(res.data)
                  setTimeout(function (){
                    new Swiper('.slidePage .slideCon .mySwiper', {
                      // 如果需要分页器
                      observer: true,//修改swiper自己或子元素时，自动初始化swiper
                      observeParents: true,//修改swiper的父元素时，自动初始化swiper
                      pagination: '.swiper-pagination',
                      effect : 'coverflow',
                      slidesPerView: 1.5,
                      centeredSlides: true,
                      coverflow: {
                        rotate: 30,
                        stretch: 10,
                        depth: 100,
                        modifier: 1.4,
                        slideShadows : true
                      },
                      //如果需要自动切换海报
                      autoplayDisableOnInteraction: false,
                      autoplay : 3000,
                      loop: true,
                      loopAdditionalSlides : 1,
                    })
                  },10)

                })
      },
      //参数切换
      toChangeItem(id){
        this.isActiveId=id
      },
      //点击播放视频
      toVideo(data) {
        this.playerOptions.sources[0].src = data
        this.isMaskShow = true
      },
      closeVideo(){
        this.isMaskShow = false
        let _this=this
        setTimeout(function (){
          _this.playerOptions.sources[0].src = ''
        },1000)
      },
      handleChange(){

      }

    },
    components:{
      baseNav,
      Copyright,
    }
  }
</script>

<style lang="less" scoped>

  #productDetail{
    padding-top: 13.888vw;
    background-color: #071D42;
    min-height: calc(100vh - 13.888vw);
  }
  .producrDetailCon{
    .slidePage{
      width: 100vw;
      height: 84.444vw;
      .title{
        font-size: 4.444vw;
        line-height: 6.3888vw;
        margin: 6.9444vw 0 5.555vw 6.666vw;
        color: #20BFD0;
        font-weight: 400;
      }
      .slideCon{
        .items{
          width: 66.111vw;
          height: 52.916vw;
          border-radius: 1vw;
          overflow: hidden;
          img{
            border-radius: 2px;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

      }
    }

    .detailConBox{
      margin:0 6.666vw 8.333vw;
      padding:2vw 4.444vw 4.444vw;
      font-size:3.3333vw;
      line-height: 5.8333vw;
      background: #fff;
      border-radius: 1.666vw;
      //.topVideo{
      //  height: 48.611vw;
      //  border-radius: 1.112vw;
      //}
      .tit{
        font-size: 4.444vw;
        margin:12vw 0 4.222vw;
        font-weight:400;
        color: #20BFD0;
      }

      // OCT写死的样式
      .tit{
        font-size: 4.444vw;
        margin:12vw 0 4.222vw;
        font-weight:400;
        color: #20BFD0;
      }
      .tit_one{
        margin: 2.2222vw 0 3vw;
      }

      img{
        width: 100%;
        height: auto;
      }
      .chuangxinCon{
        width: 100%;
        padding-bottom: 1px;
        border: 1px solid #20BFD0;
        box-sizing: border-box;
        .chuangxinTitle{
          height: 6.9444vw;
          font-size: 3.333vw;
          line-height: 6.9444vw;
          padding:0 2.777vw;
          box-sizing: border-box;
          span{
            display: inline-block;
          }
          span.arrow{
            width: 6.9444vw;
            height: 6.9444vw;
            background: url("../assets/images/down.png") no-repeat center center;
            background-size: 50%;
          }
          span.upArr{
            width: 6.9444vw;
            height: 6.9444vw;
            background: url("../assets/images/up.png") no-repeat center center;
            background-size: 50%;
          }
        }
        .chuangxinTitle.active{
          color:#fff;
          background: #20BFD0;
          span.arrow{
            width: 6.9444vw;
            height: 6.9444vw;
            background: url("../assets/images/up.png") no-repeat center center;
            background-size: 50%;
          }
        }
        .detailImg{
          position: relative;
          .videoIcon{
            display: inline-block;
            width:100%;
            height: 100%;
            background: url("../assets/images/icon_video.png") no-repeat center center;
            background-size: 15%;
            position: absolute;
            top:50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
        .desc_title{
          width: 100%;
          text-align: left;
          font-size: 3.3333vw;
          line-height: 4.5833vw;
          padding:1vw 2.2222vw;
          background:#d8d8d8;
          color:#000;
          box-sizing: border-box;
        }
      }
      .scanCon{
        li{
          display: flex;
          justify-content: space-between;
          margin-bottom:4.444vw;
          div{
            display: inline-block;
          }
          .pic{
            width: 20vw;
            height: 20vw;
            border-radius: 1.667vw;
            box-sizing: border-box;
            margin-right: 3.34vw;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .scan_con{
            margin-top: 1.1vw;
            width: 54vw;
            color:#2b2b2b;
            .scan_tit{
              font-weight: 600;
              font-size:3.8888vw;
              line-height: 5.5555vw;
            }
            .scan_desc{
              font-weight: 400;
              line-height: 4.5833vw;
              font-size:3.3333vw;
              text-align: left;
            }

          }
          .picYouhuaCon{
            img{
              width: 100%;
            }
          }
        }

      }
      .parameCon{
        font-size: 2.7777vw;
        line-height: 3.8889vw;
        text-align: justify;
        color:#2b2b2b;
        // table {
        //   tbody {
        //     .firstRow {
        //       td {
        //         width: 300!important;
        //       }
        //     }
        //   }
        // }
      }
      .pro_video{
        position: relative;
        margin-top: 1.38vw;
        img{
          width: 100%;
          height: 48.611vw;
          border-radius: 1.112vw;
        }
        .pro_video_icon{
          display: inline-block;
          width:100%;
          height: 100%;
          background: url("../assets/images/icon_video.png") no-repeat center center;
          background-size: 15%;
          position: absolute;
          top:50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
    }
  }
  .videowrapper{
    margin-top:50vh;
    transform: translateY(-50%);
  }
</style>
<style>
  .slideCon .swiper-pagination-bullet {
    width: 1.1vw !important;
    height: 1.1vw !important;
    margin: 0 0.55vw !important;
  }
  .slideCon .swiper-container {
    padding-bottom: 5.555vw;
  }
  .slideCon .swiper-pagination-bullet {
    height: 1.1vw;
    width: 1.1vw;
    background: #fff;
    position: relative;
    overflow: hidden;
    opacity: 0.5;

  }
  .slideCon .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom:0;
  }

  .slideCon .swiper-pagination-bullet-active {
    width: 6.3888vw !important;
    height: 1.1vw;
    border-radius: 0.55vw !important;
    border: 1px solid #ffffff;
    background: transparent !important;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    opacity: 1;

  }


  .slideCon .swiper-pagination-bullet-active::after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 1.1vw;
    background: #fff;
    animation: detailImgLunbo 3s linear !important;
  }

  @keyframes detailImgLunbo {
    from {
      width: 0px;
    }
    to {
      width: 100%;
    }

  }
  .el-collapse-item__header{
    height: auto!important;
    line-height: 4.5833vw!important;
    font-size:3.3333vw!important;
    padding:1vw 0 1vw 3.0555vw;
    border-bottom: 1px solid #DBDBDB!important;

  }
  .el-collapse-item__arrow {
    transform: rotate(90deg)!important;
  }
  .el-collapse-item__arrow.is-active {
    transform: rotate(-90deg)!important;
  }
  .el-collapse-item__content {
    padding-bottom: 0!important;
  }
  .el-collapse{
    border-top: none!important;
    border-bottom: none!important;
  }
  .el-collapse-item__header.is-active{
    background-color: #20BFD0!important;
    color:#fff!important;
    border-bottom-color: transparent!important;
  }
  table {
    border-collapse: collapse!important;
    border-spacing: 0!important;
  }
  tr{
    border-bottom:1px solid #ccc;
  }
  td{
    padding: 1.388vw 0;
    padding-right: 1.388vw;
  }
  td:last-child{
    padding-right: 0;
  }
</style>